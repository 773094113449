<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-server menu="datacenter"></submenu-server>

      <div class="submenu-content">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">
          <h2>{{item.intro}}</h2>
          <br/>

          <div v-for="(b, index) in item.body">

            <div v-if="b.type === 'heading'">
              <div v-if="b.value === 'Inquiry Button'">
                <br/>
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                <br/><br/>
              </div>
              <div v-else>
                <h4>{{b.value}}</h4>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="paragraph">
              <p v-html="b.value"></p>
            </div>

            <div v-else-if="b.type === 'image'">
              <img
              :src="b.value.large.src"
              :width="b.value.large.width"
              :height="b.value.large.height">
            </div>
            
            <div v-else-if="b.type === 'block'">
              
              <!-- footer images -->
              <div v-if="b.value.heading === 'footer'" class="padt">
                <img :src="b.value.image.large.src"
                  width="450" class="float-start pad">
                <img :src="b.value.image2.large.src"
                  width="450" class="float-start">
              </div>
            
              <!-- one stop shop section -->
              <div v-else-if="b.value.heading === 'one stop shop'">
                <img :src="b.value.image.medium.src"
                :width="b.value.image.medium.width"
                :height="b.value.image.medium.height" class="float-start b1">
                <div v-html="b.value.paragraph" class="float-start b2"></div>
                <div class="clear"></div>
              </div>
            </div>

            <!-- stream block: 
            the table with dark background that shows data for Alveo series from Alveo U30 to Alveo U280 -->
            <div v-else-if="b.type === 'stream_block'" class="darkbg"> 
              <div v-for="sb in b.value" class="float-start darkblock">
                <div>
                  <img :src="sb.value.image_stream[0].value.medium.src"
                        :width="sb.value.image_stream[0].value.medium.width"
                        :height="sb.value.image_stream[0].value.medium.height">
                  <div v-if="sb.value.heading"><h4 class="darktitle">{{sb.value.heading}}</h4></div>
                  <p v-if="sb.value.paragraph" v-html="sb.value.paragraph" class="darkpara"></p>
                  <!-- <div v-if="sb.value.document"><a :href="'/' +  sb.value.document" class="darklink">Datasheet &gt</a></div> -->
                  <div v-if="sb.value.link"><a :href="sb.value.link" class="darklink" target="_blank">Datasheet &gt</a></div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <!-- end of stream block-->
            
            <!-- table block: 
            the table to show data for Xilinx Qualified Servers for Data Center Acceleration Solution -->
            <div v-else-if="b.type === 'table'"> 
                <table class="table table-sm datatable">
                    <tr>
                        <td v-for="column in b.value.table_header_column" class="line">
                            <img :src="column.value.image.medium.src"
                                style="max-width: 100%;" v-if="column.value.image">
                            <div v-if="column.value.heading">{{column.value.heading}}</div>
                            <img :src="column.value.image2.medium.src"
                                height="50" v-if="column.value.image2">
                        </td>
                    </tr>
                    <tr v-for="row in b.value.data.data">
                        <td v-for="column in row">{{column}}</td>
                    </tr>
                </table>
            </div>

          </div>
        

        <!-- end of page content -->
      </div>
  
  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'DataCenter',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Server Solutions", link: "ServerSolutions"})
    breadcrumb_links.value.push({text: "Xilinx Data Center Acceleration", link: "DataCenter"})

    getAPI
    .get('/content/pages/41')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.clear {clear:both;}
.padt {margin-top:50px;}
.padl {margin-left:50px;}
.pad {margin-right:50px;}
.paragraph {margin:30px 0;}
.underline {border-bottom:1px solid #888;}


.title {padding:40px 0 0 50px; }
.darkbg {background-color:#0d131c; margin-bottom:50px;padding:20px;}
.darkblock { width: 300px; margin:20px 30px 30px 10px;; height:450px;} 
.darktitle, .darklink {color:#ad0e03; }
.darkpara {color:#ffffff;}
.darklink:link {text-decoration: none;}
.darklink:hover {text-decoration: underline;}

.datatable td {padding:20px;}
.datatable td.line {border-bottom:1px solid #ccc;}

.b1 {width:10%;}
.b2 {width:90%;}
</style>